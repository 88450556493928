var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("PageLoader", {
          attrs: {
            siloData: _vm.siloData,
            appState: _vm.appState,
            active: _vm.active,
            pageId: _vm.pageId,
          },
          on: { updateState: _vm.updateState },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }