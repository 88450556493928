var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "button" }, [
    _c(
      "div",
      {
        staticClass:
          "button__wrap r r--justify-center r--halign-center v--30 type--align-center",
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn--solid",
            on: {
              click: function ($event) {
                return _vm.$emit("updateState", _vm.instance.target)
              },
            },
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.instance.target === "close" ? "Home" : "Finish"
                ),
              },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }