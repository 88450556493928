<template>
    <div class="eh eh--card bg-color--dark">
        <div
            class="eh__content c c--content--pad r r--justify-space-between r--row-always r--halign-center pad--h v--5">
            <div class="eh__content__home-link">
                <button @click="$emit('close')">
                    <Icon name="icon-home" />
                </button>
            </div>
            <div class="eh__content__title">
                <span>{{titleAdjusted}}</span>
            </div>
            <div class="eh__content__logout">
                <button onclick="uma.logout();return false;" aria-title="Logout">
                    <div class="icon icon--logout"></div>
                </button>
            </div>
        </div>
        <div class="eh__bg"></div>
    </div>
</template>

<script>
import Icon from '@c/Icon.vue';
export default {
    components: {
        Icon,
    },
    data() {
        return {

        }
    },
    props: {
        title: String,
    },
    computed: {
        titleAdjusted() {
            let returned = "";

            switch (this.title) {
                case "help":
                    returned = "Crave/Slip";
                    break;
                case "tasks":
                    returned = "My Tasks";
                    break;
                case "plan":
                    returned = "My Plan";
                    break;
                case "bonus-topics":
                    returned = "Bonus Topics";
                    break;
                default:
                    returned = "";
                    break;
            }
            return returned;
        }
    },
}
</script>

<style lang="scss" scoped>
    /*
.eh {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
}

.eh__bg {
    height: var(--height--header-m);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: purple;
    z-index: 0;
    transition: all .4s;
    transform: translateY(-100%);
}

.eh__content {
    z-index: 1;
}
*/
</style>