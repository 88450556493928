<template>
    <span v-if="siloData.user.id !== 0" class="card" :data-state="state">
        <div class="card__mover" :style="`transition-duration: ${loadModule}`">
            <div class="card__container">
                <EntryHeaderCard :title="activator" @close="close()"/>
                <div class="" :class="hasChildren === false ? 'ms ms--regular' : ''">
                    <div class="" :class="hasChildren === false ? 'ms__wrap c c--content--pad r pad--h v--40' : ''">
                        <Help v-if="activator === 'help'" :siloData="siloData" :appState="appState" :active="activator === 'help' && state === 'active' ? true : false" @processing="setProcessing" @refreshData="refreshData" @updateState="updateState"/>
                        <Tasks v-if="activator === 'tasks'" :siloData="siloData" :appState="appState" :active="activator === 'tasks' && state === 'active' ? true : false" @processing="setProcessing" @refreshData="refreshData"/>
                        <Plan v-if="activator === 'plan'" :siloData="siloData" :active="activator === 'plan' && state === 'active' ? true : false" @processing="setProcessing" @refreshData="refreshData"/>
                    </div>
                    <div class="ms__bg"></div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import EntryHeaderCard from '@c/EntryHeaderCard.vue'
import Help from '@c/Help.vue'
import Tasks from '@c/Tasks.vue'
import Plan from '@c/Plan.vue'
export default {
    components: {
        EntryHeaderCard,
        Help,
        Tasks,
        Plan,
    },
    data() {
        return {
            state: "inactive",
            anims: {
                loadModuleJS: 550,
            },
        }
    },
    computed: {
        loadModule() {
            return `${this.anims.loadModuleJS}ms`;
        }
    },
    props: {
        siloData: Object,
        appState: String,
        activator: String,
        hasChildren: Boolean,
    },
    watch: {
        appState() {
            if (this.appState === this.activator) {
                this.open();
            }
        },
    },  
    mounted() {
        this.checkForCardQueryString();
    },
    methods: {
        checkForCardQueryString() {
            console.log('CHECKING FOR QUERY STRING');
            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('pg') && urlParams.get('pg') === this.activator) {
                this.open();
                console.log('OPENING CARD');
            }
        },
        updateUrl(string = "") {
            console.log('UPDATING URL');
            var url = new URL(window.location);
            if (string !== "") {
                console.log('STRING IS NOT BLANK');
                url.searchParams.set('pg', this.activator);
            } else {
                console.log('STRING IS BLANK');
                url.searchParams.delete('pg');
            }

            window.history.pushState({}, '', url);
        },
        open() {
            this.state = 'active';
            this.$emit('updateState', 'card');
            // Set the url
            this.updateUrl(this.activator);
        },
        close() {
            this.state = 'inactive';
            // I think I probably need to update the state here
            this.$emit('updateState', 'home');
            // Clear the url
            this.updateUrl();
        },
        setProcessing(state) {
            console.log('setting processing (module slide)');
            this.$emit('processing', state);
        },
        refreshData(onComplete = null) {
            console.log('refreshing data (module slide)');
            this.$emit('refreshData', onComplete);
        },
        updateState() {
            this.state = 'inactive';
            this.updateUrl();
            window.app.updateState('bonus-topics');
        },
    }        
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";

.card {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: $z_top;

    >* {
        width: 100%;
        transition-property: all;
        transition-timing-function: $easing_regular;
        transform: translateY(100%);
    }

    &[data-state="active"] {
        pointer-events: inherit;

        >* {
            transform: translateY(0);
        }
    }
    &[data-state="inactive"] {
        .eh--card {
            opacity: 0;
            position: relative;
        }
    }
}
.card__container {
    height: 100%;
    transition: all 0.15s;
}
.ms {
    padding-top: rem(20px);
}

</style>

<style lang="scss" >
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.card {
    .btn {
        color: var(--color-purple);
    }

    a:not(.btn),
    .link {
        color: var(--color-purple);
    }

    h2,
    h1,
    h5 {
        color: var(--color-purple);
    }
}
.header {
    &--icon {
        svg {
            * {
                fill: $color_purple;
            }
        }

        >*+* {
            margin-left: rem(10px);
        }
    }
}
</style>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.ms__wrap {
    //background-color: var(--color-white);
}
</style>