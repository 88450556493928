import { render, staticRenderFns } from "./IconIntro.vue?vue&type=template&id=647962af&"
import script from "./IconIntro.vue?vue&type=script&lang=js&"
export * from "./IconIntro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nicholashaskell/Local/kicknic/app/public/wp-content/themes/kicknic/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('647962af')) {
      api.createRecord('647962af', component.options)
    } else {
      api.reload('647962af', component.options)
    }
    module.hot.accept("./IconIntro.vue?vue&type=template&id=647962af&", function () {
      api.rerender('647962af', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/scripts/components/IconIntro.vue"
export default component.exports