<template>
    <div class="button">
        <div class="button__wrap r r--justify-center r--halign-center v--30 type--align-center">
            <button class="btn btn--solid" @click="$emit('updateState',instance.target)">
                <span v-text="instance.target === 'close' ? 'Home' : 'Finish'"></span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        props: {
            instance: Object,
            siloData: Object,
        },
    }
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.button__wrap {
    padding-top: rem(20px);
}
.btn--solid {
    color: var(--color-white) !important;
 }
</style>