var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c("span", { staticStyle: { display: "none" }, attrs: { id: "cm" } }, [
        _c("div", { ref: "cm", staticClass: "cm v--40" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cm__nav r r--justify-center r--halign-center" },
            [
              _c(
                "button",
                { staticClass: "btn btn--solid", on: { click: _vm.close } },
                [_vm._v("Close")]
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cm__top v--10" }, [
      _c("div", { staticClass: "cm__tag" }, [
        _c("span", [_vm._v("Great Job!")]),
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "quote__text type--color--purple" }, [
        _vm._v(
          "\n\t\t\t\tGet crave/slip help in the Bonus Topics and access to the Vape Free Tracker from the toolbar at the bottom of the homescreen.\n\t\t\t"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }