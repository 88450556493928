var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "eh eh--card bg-color--dark" }, [
    _c(
      "div",
      {
        staticClass:
          "eh__content c c--content--pad r r--justify-space-between r--row-always r--halign-center pad--h v--5",
      },
      [
        _c("div", { staticClass: "eh__content__home-link" }, [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_c("Icon", { attrs: { name: "icon-home" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "eh__content__title" }, [
          _c("span", [_vm._v(_vm._s(_vm.titleAdjusted))]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "eh__bg" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "eh__content__logout" }, [
      _c(
        "button",
        {
          attrs: {
            onclick: "uma.logout();return false;",
            "aria-title": "Logout",
          },
        },
        [_c("div", { staticClass: "icon icon--logout" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }