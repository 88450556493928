<template>
    <span v-if="siloData.user.id !== 0" id="moduleloader" @resetModule="reset" :data-state="state" :data-slide-direction="transitionName">
        <div class="moduleloader" :data-processing="processing" :data-appstate="appState" :data-module-theme="current_module_theme" :style="`transition-duration: ${loadModule}`">
            <div v-if="current_module_data" class="module" :data-active-slide="active_slide">

                <EntryHeaderModules :slideParent="current_module_data[0]" :slides="current_module_data" :activeSlide="active_slide" @close="reset()"/>
                
                <TransitionGroup :name="transitionName" @leave="leave" @enter="enter" @after-enter="afterEnter" tag="div" style="height:100%">

                    <div v-for="(slide, index) in current_module_data" 
                        v-show="active_slide === index"
                        class="module__slide" 
                        :class="active_slide === index ? 'active' : 'inactive'"
                        :data-slide="index" 
                        :key="index">
                        
                        <ModuleSlideIntro 
                            v-if="index == 0" 
                            :sd="slide" 
                            :index="index" 
                            :slides="current_module_data" 
                            @next="next"/>

                        <ModuleSlide 
                            v-else
                            :sd="slide" 
                            :index="index" 
                            :slides="current_module_data"
                            :siloData="siloData"
                            :activeSlide="active_slide"
                            :active="active_slide === index ? true : false"
                            @processing="setProcessing"
                            @refreshData="refreshData"
                            @next="next" 
                            @prev="prev"
                            @close="reset()"/>

                    </div>

                </TransitionGroup>

            </div>
        </div>

        <WelcomeModal :appState="appState" :siloData="siloData" :active="showWelcomeActive" :module_id="current_module_theme" @moduleWelcomeReset="moduleWelcomeReset" @refreshData="refreshData"/>

    </span>
</template>

<script>
import EntryHeaderModules from '@c/EntryHeaderModules.vue'
import ModuleSlide from '@c/ModuleSlide.vue'
import ModuleSlideIntro from '@c/ModuleSlideIntro.vue'
import WelcomeModal from '@c/WelcomeModal.vue'
const axios = require('axios');

export default {
    components: {
        EntryHeaderModules,
        ModuleSlide,
        ModuleSlideIntro,
        WelcomeModal,
    },
    data() {
        return {
            state: "inactive",
            processing: false,
            current_module_data: null,
            current_module_theme: 0,
            active_slide: 0,
            anims: {
                loadModuleJS: 550,
                hideSpeedJS: 750,
            },
            transitionName: 'show-next',
            showWelcomeActive: false,
        }
    },
    computed: {
        loadModule() {
            return `${this.anims.loadModuleJS}ms`;
        }
    },
	props: {
		appState: String,
		siloData: Object,
	},
    mounted() {
        // Allow access to this script from vanilla js
        window.moduleloader = this;

        // Check for the ?mod=29 query string
        this.checkForModuleQueryString();
    },
    watch: {
        // Watch for changes in the appState
        // If it gets set to "home" reset it to 0
        // So the next time the user comes back, it's set to page 1
        appState() {
            if (this.appState === "home") {
                setTimeout(()=> {
                    console.log('reseting active slide');
                    this.active_slide = 0;
                    this.destroy();
                }, this.anims.hideSpeedJS);
            }
        },
    },
    methods: {
        destroy() {
            console.log('Destroying module slides!');
            this.current_module_data = null;
            this.current_module_theme = 0;
        },
        checkForModuleQueryString() {
            let urlParams = new URLSearchParams(window.location.search);
            //console.log(urlParams.has('module')); // true
            //console.log(urlParams.get('module')); // "MyParam"

            if (urlParams.has('mod')) {
                this.open(urlParams.get('mod'));
            }
        },
        runOnLoadChecks() {
            let urlParams = new URLSearchParams(window.location.search);

            // Check for query strings
            if (urlParams.has('slug') || urlParams.has('slide')) {
                this.checkForSlideQueryString();
                // If there are none, check for last active slide
            } else {
                this.checkForLastActiveSlide();
            }
        },
        checkForSlideQueryString() {
            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('slide')) {
                let int = parseInt(urlParams.get('slide'));
                this.active_slide = int - 1; // this comes in as a string, it has to be a number
                
                // if the slide number is greater than 0 set module theme
                if (int > 0) {
                    this.$emit('updateState', 'module');
                }
            }

            this.updateUrl(); // update url
            this.postPageView();
            this.showWelcomeModal();
        },
        // Look to see if there is a last active slide for this module
        // NOTE: If this works, I need to make it so that the query
        //       SUPERCEDES THIS, otherwise we won't be able to
        //        navigate directly to a page
        checkForLastActiveSlide() {
            let last_active_slide = this.siloData.user.progress[this.current_module_theme - 1].last_active_slide;
            // Make sure we have a last active slide set
            if (last_active_slide !== "" && last_active_slide !== 0) {
                // Set the current active slide to the last active slide
                this.active_slide = parseInt(last_active_slide);
                // I think I need this here but I'm not sure
                this.$emit('updateState', 'module');

                this.updateUrl(); // update url
                this.postPageView();
            }
        },
        open(id) {
            this.getModule(id);
        },
        getModule(id) {
            this.$emit('processing', true);
            axios.post(this.siloData.urls.getmodule, {
                id: id,
            }).then((result) => {
                console.log(result);
                
                this.current_module_data = result.data;
                this.current_module_theme = this.current_module_data[0].order;

                this.updateState('module-intro'); // Handle theming
                this.state = 'active'; // show modal
                
                setTimeout(() => {
                    this.$emit('processing', false);
                }, this.anims.loadModuleJS);
                
                // Looks for query strings and then looks for last active slide
                this.runOnLoadChecks();

            }).catch((reason) => {
                console.log(reason);
                setTimeout(() => {
                    this.$emit('processing', false);
                }, this.anims.loadModuleJS);
            });
        },
        next() {
            this.transitionName = 'show-next';
            this.active_slide += 1;
            this.trackProgression();
            this.updateUrl();
            this.postPageView();

            if (this.active_slide > 0) {
                this.$emit('updateState', 'module');
            }
        },
        prev() {            
            this.transitionName = 'show-prev';
            this.active_slide -= 1;
            this.trackProgression();
            this.updateUrl();
            this.postPageView();

            if (this.active_slide === 0) {

                this.$emit('updateState', 'module-intro');
            }
        },
        // Not sure this is working
        scrollToTop() {
            document.querySelectorAll('.module__slide.active .ms').forEach((item) => { item.scrollTop = 0; });
        },
        // Reset the module back to starting position
        reset() {
            this.state = 'inactive';
            this.$emit('updateState', 'home');

            // Just refresh the data whenever we close
            // I think I want to do this somewhere else!
            // this.$emit('refreshData');

            // Set the active slide to 0 after the slider hides itself
            // This way we don't see the transition back to the first slide

            // Check to see if the active slide is the last slide
            let slide_last = this.current_module_data.length - 1;

            // If it is the last slide, show the complete modal
            if (this.active_slide === slide_last) {
                // Emit show module complete
                this.$emit('moduleComplete');
            }

            setTimeout(()=> {
                console.log('reseting active slide');
                this.active_slide = 0;
                this.showWelcomeActive = false;
            }, this.anims.hideSpeedJS);

            // Clear the url
            window.history.pushState({}, document.title, window.location.pathname);
        },
        // transition group callback when it leaves
        // NOTE: MAY NOT BE NEEDED
        leave() {
            //console.log('LEAVING');
            let event = new CustomEvent('slideLeave', { detail: { current: this.active_slide, direction: this.transitionName } });
            window.dispatchEvent(event);
        },
        // transition group callback after it enters
        // NOTE: MAY NOT BE NEEDED
        enter() {
            //console.log('ENTERING');
            this.scrollToTop();
            let event = new CustomEvent('slideEnter', { detail: { current: this.active_slide, direction: this.transitionName } });
            window.dispatchEvent(event);
            //window.dispatchEvent(this.events.enter, { detail: { current: this.active_slide, direction: this.transitionName } });
        },
        // NOTE: MAY NOT BE NEEDED
        afterEnter() {
            //console.log('AFTER ENTER');
            let event = new CustomEvent('slideAfterEnter', { detail: { current: this.active_slide, direction: this.transitionName } });
            window.dispatchEvent(event);
        },
        // This runs on every page change
        // It allows us to determine when we update module status, 
        // fire event tracking events (future) and slide progression (future)
        trackProgression() {
            // The theme is based on the order, which seems weird but is correct
            let module_id = this.current_module_theme;
            // console.log("current module", module_id);

            // Need to deal with 0 indexing on array here
            let module_status = this.siloData.user.progress[this.current_module_theme - 1].status;
            // console.log("current module status", module_status);

            let slide = this.active_slide;
            // console.log("current slide", slide);

            // Adjusted for 0 indexing
            let slide_last = this.current_module_data.length - 1;
            // console.log("last slide", slide_last);

            // If not the first or last slide
            if (slide !== 0 && slide !== slide_last) {
                // Push the last active slide number so we can come back to that
                this.updateLastActiveSlide(module_id, slide);
            }

            // If it is the last slide
            if (slide === slide_last) {
                // Reset the last active slide so we start at the beginning next time
                this.updateLastActiveSlide(module_id, "0");
            }

            if (slide === 1 && module_status !== "in-progress" && module_status !== "complete") {
                console.log(`setting module ${module_id} to in progress`);
                this.updateModuleStatus(module_id, "in-progress");
                // Post module started event
                this.postModuleStarted();
            }

            if (slide === slide_last && module_status !== "complete") {
                console.log(`setting module ${module_id} to complete`);
                this.updateModuleStatus(module_id, "complete");
                // Post module completion event
                this.postModuleCompleted();
            }
        },
        sendHalfWayNotification(module_id) {
            // This only happens once you've completed 4!
            if (module_id !== 4) { return; }
            // Send SMS
            axios(this.siloData.urls.notificationhalfway);
        },
        // Send data to track where we left off
        updateLastActiveSlide(module_id, value) {
            let data = [];
            let key = `meta_t${module_id}_last_active_slide`;
            let data_status = {
                key,
                value
            }
            data.push(data_status);

            axios.post(this.siloData.urls.postuserdata, data).then(response => {
                this.$emit('refreshData');
            });
        },
        updateModuleStatus(module_id, value) {

            let data = [];
            let key = `meta_m${module_id}_status`;
            let data_status = {
                key,
                value
            }
            data.push(data_status);


            // Add a started date value for in progress
            if (value === "in-progress") {
                let completion_data = {
                    key: `meta_m${module_id}_started_date`,
                    value: new Date()
                };
                data.push(completion_data);

                // Start in progress timer
                let sms_progress_timer = {
                    key: "meta_us_inprogress_module_reminder_level",
                    value: "3"
                }
                data.push(sms_progress_timer);

                // Restart complete timer
                let sms_complete_timer = {
                    key: "meta_us_completed_module_reminder_level",
                    value: ""
                }
                data.push(sms_complete_timer);
            }


            // If we are completing a module, also pass up the date of completion
            if (value === "complete") {
                let completion_date_key = `meta_m${module_id}_completion_date`;
                let completion_date_value = new Date(); // date ? not sure of format

                let completion_data = {
                    key: completion_date_key,
                    value: completion_date_value
                };
                data.push(completion_data);

                // Allow rush message to be show again
                let rush_flag_data = {
                    key: "meta_us_can_show_rush_date_message",
                    value: "true"
                }
                data.push(rush_flag_data);

                // Reset in progress timer
                let sms_progress_timer = {
                    key: "meta_us_inprogress_module_reminder_level",
                    value: ""
                }
                data.push(sms_progress_timer);


                // 7 is the last one, so if the last one goes off, we don't have to set this
                if (module_id !== 7) {
                    // Start complete timer
                    let sms_complete_timer = {
                        key: "meta_us_completed_module_reminder_level",
                        value: "3"
                    }
                    data.push(sms_complete_timer);
                } else {
                    let sms_complete_timer = {
                        key: "meta_us_completed_module_reminder_level",
                        value: ""
                    }
                    data.push(sms_complete_timer);
                }
            }

            console.log(data);

            // axios request here
            axios.post(this.siloData.urls.postuserdata, data).then(response => {
                console.log('status updated!', response.data);
                
                // refresh our data so we can see changes on the home page
                this.$emit('refreshData');
                // Check / send hald way notification
                this.sendHalfWayNotification(module_id);
            });
        },
        updateState(newState) {
            this.$emit('updateState', newState);
        },
        setProcessing(state) {
            console.log('setting processing (module loader)');
            this.$emit('processing', state);
        },
        refreshData(onComplete = null) {
            console.log('refreshing data (module loader)');
            this.$emit('refreshData', onComplete);
        },
        updateUrl() {
            var url = new URL(window.location);

            url.searchParams.set('mod', this.current_module_data[0].ID);

            if (this.active_slide === 0) {
                url.searchParams.delete('slide');
            } else {
                url.searchParams.set('slide', this.active_slide + 1);
            }

            window.history.pushState({}, '', url);
            console.log('updating url to', url);
        },
        postPageView() {
            let data = {
                type: this.active_slide === 0 ? 'moduleintro' : 'module',
                moduleNumber: this.current_module_theme,
                moduleTitle: this.current_module_data[0].title,
                screenNumber: this.active_slide + 1,
                url: new URL(window.location),
            };
            window.events.postPageView(data);
        },
        showWelcomeModal() {
            console.log('attempting to show welcome modal');
            if (this.active_slide === 0) {
                // If we still don't have a current module theme, wait a beat and then try again
                if (this.current_module_theme === 0) {
                    console.log('we still dont have a current module theme, trying again');
                    setTimeout(() => {
                        this.showWelcomeModal();
                    }, 0.25);
                } else {
                    console.log('showing welcome modal');
                    this.showWelcomeActive = true;
                }
            }
        },
        moduleWelcomeReset() {
            this.showWelcomeActive = false;
        },
        /*
        showWelcomeModal() {
            this.showWelcomeActive = true;
        },
        */
        // Post module started event
        postModuleStarted() {
            let data = {
                type: 'started',
                moduleNumber: this.current_module_theme,
                moduleTitle: this.current_module_data[0].title,
            };
            window.events.postAction(data);
        },
        // Post module completion event
        postModuleCompleted() {
            let data = {
                type: 'completed',
                moduleNumber: this.current_module_theme,
                moduleTitle: this.current_module_data[0].title,
            };
            window.events.postAction(data);
        },
    },
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";


/*
.module__slide {
    opacity: 0;
    transition: all 2.5s;
    &[data-active="true"] {
        opacity: 1;
    }
}
*/

.show-next-enter-active,
.show-prev-enter-active {
  transition: all 0.4s;
  //position: absolute;
}

.show-next-leave-active,
.show-prev-leave-active {
  transition: all 0.4s;
  //position: absolute;
}

.show-next-enter,
.show-prev-leave-to {
  transform: translateX(100%);
}

.show-next-leave-to,
.show-prev-enter {
  transform: translateX(-100%);
}

#moduleloader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: $z_top;
}
.moduleloader {
    width: 100%;
    transition-property: all;
    transition-timing-function: $easing_regular;
    transform: translateY(100%);
}
.module {
    height: 100%;
    transition: all 0.15s;
}
#moduleloader {
    &[data-state="active"] {
        pointer-events: inherit;
        .moduleloader {
            transform: translateY(0);
            .module {
                //transform: translateY(0);
                //z-index: 2;
            }
        }
    }
    &[data-state="inactive"] {
        pointer-events: none;
        .moduleloader {
            transform: translateY(100%);
        }
    }
}


// Probably don't need this
.module__slide {
    width: 100vw;
    height: 100%;
    position: absolute;
    > * {
        width: 100vw;
    }
}
</style>