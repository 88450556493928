import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=568024e6&"
import script from "./Events.vue?vue&type=script&lang=js&"
export * from "./Events.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nicholashaskell/Local/kicknic/app/public/wp-content/themes/kicknic/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('568024e6')) {
      api.createRecord('568024e6', component.options)
    } else {
      api.reload('568024e6', component.options)
    }
    module.hot.accept("./Events.vue?vue&type=template&id=568024e6&", function () {
      api.rerender('568024e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/scripts/components/Events.vue"
export default component.exports