import { render, staticRenderFns } from "./PageLoader.vue?vue&type=template&id=6af38d14&scoped=true&"
import script from "./PageLoader.vue?vue&type=script&lang=js&"
export * from "./PageLoader.vue?vue&type=script&lang=js&"
import style0 from "./PageLoader.vue?vue&type=style&index=0&id=6af38d14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af38d14",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nicholashaskell/Local/kicknic/app/public/wp-content/themes/kicknic/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6af38d14')) {
      api.createRecord('6af38d14', component.options)
    } else {
      api.reload('6af38d14', component.options)
    }
    module.hot.accept("./PageLoader.vue?vue&type=template&id=6af38d14&scoped=true&", function () {
      api.rerender('6af38d14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/scripts/components/PageLoader.vue"
export default component.exports