var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "parent",
      staticClass: "text v--15",
      class: `text--${_vm.instance.class}`,
    },
    [
      _vm.instance.title
        ? _c("h2", {
            ref: "title",
            staticClass: "text__title type--h2",
            class: _vm.instance.title_icon ? _vm.instance.title_icon : "",
            domProps: { innerHTML: _vm._s(_vm.instance.title) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.instance.include_urge_surfing
        ? _c(
            "div",
            { staticClass: "urge-surfing" },
            [_c("Icon", { attrs: { name: "urge-surfing-5" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.instance.content
        ? _c("div", {
            ref: "content",
            staticClass:
              "text__content type--content-styles type--content-margins type--content-links",
            domProps: { innerHTML: _vm._s(_vm.instance.content) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }