var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cs v--30" },
    [
      _c("div", { staticClass: "cs__top v--15" }, [
        _c("h2", {
          staticClass: "cs__title type--h2",
          domProps: { innerHTML: _vm._s(_vm.instance.title) },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "cs__content type--content-links type--content-margins type--content-styles",
          domProps: { innerHTML: _vm._s(_vm.instance.content) },
        }),
      ]),
      _vm._v(" "),
      _c(
        "VueSlickCarousel",
        _vm._b(
          { ref: "slider", staticClass: "cs__slider" },
          "VueSlickCarousel",
          _vm.settings,
          false
        ),
        _vm._l(_vm.instance.team, function (member, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "cs__card r",
              attrs: {
                "data-member-name": member.name,
                "data-watched": "false",
              },
            },
            [
              _c("div", { staticClass: "cs__card__wrap" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "cs__card__container r r--justify-center v--15",
                  },
                  [
                    member.name !== "na"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "cs__card__top r r--justify-center r--halign-center v--5",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cs__card__image vt__member__image",
                              },
                              [
                                _c("PictureStaticAsset", {
                                  attrs: {
                                    url_sd: _vm.getImage(member).sd,
                                    url_hd: _vm.getImage(member).hd,
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "cs__card__image__bg vt__member__image__bg",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("h5", { staticClass: "cs__card__name" }, [
                              _vm._v(_vm._s(member.name)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cs__card__bottom v--10",
                        class:
                          member.wysiwyg === "" ? "type--content-styles" : "",
                      },
                      [
                        member.header
                          ? _c("p", { staticClass: "cs__card__header" }, [
                              _c("strong", [_vm._v(_vm._s(member.header))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        member.content
                          ? _c("p", { staticClass: "cs__card__content" }, [
                              _c("em", [_vm._v(_vm._s(member.content))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        member.wysiwyg
                          ? _c("div", {
                              staticClass:
                                "cs__card__wysiwyg type--content-styles v--15",
                              domProps: { innerHTML: _vm._s(member.wysiwyg) },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }