<template>
    <span v-if="siloData.user.id !== 0" class="card" :data-state="state">
        <div class="card__mover" :style="`transition-duration: ${loadModule}`">
            <div class="card__container">
                <EntryHeaderCard :title="activator" @close="close()" />
                <div class="ms ms--regular">
                    <div class="ms__wrap c c--content--pad r pad--h v--40">


                        <div class="menubonus">
                            <div class="text  v--25">
                                <div class="plans__top type--content-styles type--content-margins type--content-links">
                                    <h2 class="text__title header icon icon--bonus r r--row-always r--halign-center">
                                        Bonus Topics
                                    </h2>
                                </div>
                                <div class="plans__bottom modules">

                                    <!-- buttons -->
                                    <template v-for="(mod, index) in modules">

                                        <template v-if="index === 2">
                                            <!-- false button -->
                                            <div class="modules__module r" data-module="4">
                                                    <button @click="close();$emit('updateState', 'help')"
                                                        class="modules__module__button r r--halign-center" title="Slip Help">

                                                        <div class="modules__module__button__wrap r r--halign-center r--row-always">
                                                            <div class="modules__module__button__icons">
                                                                <div class="modules__module__button__icons__icon r r--justify-center r--halign-center">
                                                                    <Icon :name="getModuleIconName(4)" />
                                                                    <div class="modules__module__button__icons__icon__ring r r--justify-center r--halign-center">
                                                                        <div class="modules__module__button__icons__icon__ring__inner"></div>
                                                                        <div class="modules__module__button__icons__icon__ring__outer"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h3 class="modules__module__button__text">
                                                                <span class="r r--justify-center">Crave/Slip Help</span>
                                                            </h3>
                                                        </div>
                                                    </button>
                                                </div>
                                            <!-- end false button -->
                                        </template>


                                        <div class="modules__module r" :data-module="(index + 1)" :data-status="getStatus(index)" :data-is-first="(index === 0 ? 'true' : 'false')">
                                            <button @click="openModule(mod.ID)"
                                                class="modules__module__button r r--halign-center" :title="mod.title">
                                                <div class="modules__module__button__wrap r r--halign-center r--row-always">
                                                    <div class="modules__module__button__icons">
                                                        <div class="modules__module__button__icons__icon r r--justify-center r--halign-center">
                                                            <Icon :name="getModuleIconName(mod.order)" />
                                                            <div class="modules__module__button__icons__icon__ring r r--justify-center r--halign-center">
                                                                <div class="modules__module__button__icons__icon__ring__inner"></div>
                                                                <div class="modules__module__button__icons__icon__ring__outer"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h3 class="modules__module__button__text">
                                                        <span class="r r--justify-center">{{mod.title}}</span>
                                                    </h3>
                                                </div>
                                            </button>
                                        </div>



                                    </template>
                                    <!-- end buttons -->

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ms__bg"></div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import Icon from "@c/Icon.vue"
//import Loading from "@c/Loading.vue"
import EntryHeaderCard from '@c/EntryHeaderCard.vue'
const axios = require('axios');
export default {
    components: {
        EntryHeaderCard,
        Icon,
        //Loading,
    },
    data() {
        return {
            modules: null,
            state: "inactive",
            anims: {
                loadModuleJS: 550,
            },
        }
    },
    computed: {
        loadModule() {
            return `${this.anims.loadModuleJS}ms`;
        }
    },
    props: {
        siloData: Object,
        appState: String,
        activator: String,
        hasChildren: Boolean, // not sure i need this
        processing: Boolean,
    },
    watch: {
        appState() {
            if (this.appState === this.activator) {
                this.open();
            }
            if (this.appState === 'bonus' || this.appState === 'bonus-intro') {
                if (this.state === 'active') {
                    this.close_soft();
                }
            }
        },
    },
    mounted() {
        this.getModules();
        this.checkForCardQueryString();
    },
    methods: {
        getModuleIconName(order) {
            return "bonus/icon-bonus-" + order;
        },
        checkForCardQueryString() {
            console.log('CHECKING FOR QUERY STRING');
            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has('pg') && urlParams.get('pg') === this.activator) {
                this.open();
                console.log('OPENING CARD');
            }
        },
        updateUrl(string = "") {
            console.log('UPDATING URL');
            var url = new URL(window.location);
            if (string !== "") {
                console.log('STRING IS NOT BLANK');
                url.searchParams.set('pg', this.activator);
            } else {
                console.log('STRING IS BLANK');
                url.searchParams.delete('pg');
            }

            window.history.pushState({}, '', url);
        },
        open() {
            this.state = 'active';
            this.$emit('updateState', 'card');
            // Set the url
            this.updateUrl(this.activator);
            // trigger view event
            this.postPageView();
        },
        close() {
            this.state = 'inactive';
            // I think I probably need to update the state here
            this.$emit('updateState', 'home');
            // Clear the url
            this.updateUrl();
        },
        // Not quitting back to homescreen
        close_soft() {
            this.state = 'inactive';
        },
        setProcessing(state) {
            console.log('setting processing (module slide)');
            this.$emit('processing', state);
        },
        refreshData(onComplete = null) {
            console.log('refreshing data (module slide)');
            this.$emit('refreshData', onComplete);
        },
        getStatus(index) {
            // take the index, which will be the module number
            // based on that, get look for the coorelated index in siloData.user.
            return this.siloData.user.progress[index].status ? this.siloData.user.progress[index].status : "open";
        },
        getModules() {
            axios(this.siloData.urls.getmodules + '&type=bonus').then((result) => {
                console.log(result);
                this.modules = result.data;
            }).catch((reason) => {
                console.log(reason);
            });
        },
        // ! THIS WILL HAVE TO CHANGE
        openModule(id) {

            // We need to clear out any query strings, otherwise when we open the URL
            // it will make the modal act strange
            this.clearQueryStrings();

            // True allows this to open with a slug
            // THIS WILL HAVE TO BE BONUS LOADER OR SOMETHING
            window.bonusloader.open(id, true);
        },
        clearQueryStrings() {
            // Get the current url without query strings
            let cleanUrl = window.location.href.replace(window.location.search, '');
            // Update the URL without reloading
            window.history.replaceState({}, window.title, cleanUrl);
        },
        postPageView() {
            let data = {
                type: 'menu',
                title: 'Bonus Topics',
            };
            window.events.postPageView(data);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";

.card {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: $z_top;

    >* {
        width: 100%;
        transition-property: all;
        transition-timing-function: $easing_regular;
        transform: translateY(100%);
    }

    &[data-state="active"] {
        pointer-events: inherit;

        >* {
            transform: translateY(0);
        }
    }
}

.card__container {
    height: 100%;
    transition: all 0.15s;
}

.ms {
    padding-top: rem(20px);
}

.ms__loading {
    margin-top: rem(50px);
}

.topic__wrap {
    gap: rem(20px);
    justify-content: center;
    align-items: center;
}

.topic__status {
    display: flex;
    flex-direction: center;
    align-items: center;
}

.topic__status__icon {
    width: rem(33px);
    height: rem(33px);
    border-radius: 100%;
    border: 1px solid var(--color-dark-grey);
}

.topic__button {
    background-color: var(--color-light-grey);
    border-radius: rem(8px);
    gap: rem(15px);
    padding: rem(12px) rem(20px);
    flex-direction: row;
    justify-content: space-between;

    &:hover,
    &:focus {
        background-color: var(--color-medium-light-grey);
    }
}

.topic__button__title {}

.topic__button__image {
    // ! update this when I get an image
    height: 49px;
    width: 90px;
    overflow: hidden;

    //border-radius: 8px;
    //background-color: var(--color-dark-grey);
    >* {
        width: 100%;
        height: auto;
    }
}

.topic[data-locked="true"] {
    .topic__status__icon {
        background-color: #bdbdbd;
        border-color: #bdbdbd;
    }

    .topic__button {
        pointer-events: none;
        opacity: 0.5;

        svg {
            filter: grayscale(0);
        }
    }
}

[data-processing="true"] {}

.ms__bg {
    background-color: var(--color-white);
}

/* Overrides ------------------------------------------------------ */

.modules {
    margin-top: 0;
    gap: rem(70px);
  display: flex;
  flex-direction: column;
}
.modules__module {
    align-items: center;
}
.modules__module__button {
    width: rem(215px);
}
.modules__module__button__wrap {
    gap: rem(20px);
}
.modules__module__button__text {
    max-width: rem(110px);
    text-align: left;
    span {
        word-break: keep-all;
    }
}

/* Themes ------------------------------------------------------ */

.modules__module {
    &[data-module="1"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-b1-dark), var(--color-b1-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-b1-light);
                }
            }
        }
    }
    &[data-module="2"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-b2-dark), var(--color-b2-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-b2-light);
                }
            }
        }
    }
    &[data-module="3"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-b3-dark), var(--color-b3-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-b3-light);
                }
            }
        }
    }
    &[data-module="4"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-b4-dark), var(--color-b4-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-b4-light);
                }
            }
        }
    }
}
</style>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";

.card {}

.header {}
</style>