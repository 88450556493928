var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.siloData.user.id !== 0
    ? _c("span", { staticClass: "card", attrs: { "data-state": _vm.state } }, [
        _c(
          "div",
          {
            staticClass: "card__mover",
            style: `transition-duration: ${_vm.loadModule}`,
          },
          [
            _c(
              "div",
              { staticClass: "card__container" },
              [
                _c("EntryHeaderCard", {
                  attrs: { title: _vm.activator },
                  on: {
                    close: function ($event) {
                      return _vm.close()
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ms ms--regular" }, [
                  _c(
                    "div",
                    {
                      staticClass: "ms__wrap c c--content--pad r pad--h v--40",
                    },
                    [
                      _c("div", { staticClass: "menubonus" }, [
                        _c("div", { staticClass: "text v--25" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "plans__bottom modules" },
                            [
                              _vm._l(_vm.modules, function (mod, index) {
                                return [
                                  index === 2
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "modules__module r",
                                            attrs: { "data-module": "4" },
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "modules__module__button r r--halign-center",
                                                attrs: { title: "Slip Help" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.close()
                                                    _vm.$emit(
                                                      "updateState",
                                                      "help"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modules__module__button__wrap r r--halign-center r--row-always",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "modules__module__button__icons",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "modules__module__button__icons__icon r r--justify-center r--halign-center",
                                                          },
                                                          [
                                                            _c("Icon", {
                                                              attrs: {
                                                                name: _vm.getModuleIconName(
                                                                  4
                                                                ),
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _vm._m(1, true),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm._m(2, true),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "modules__module r",
                                      attrs: {
                                        "data-module": index + 1,
                                        "data-status": _vm.getStatus(index),
                                        "data-is-first":
                                          index === 0 ? "true" : "false",
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "modules__module__button r r--halign-center",
                                          attrs: { title: mod.title },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openModule(mod.ID)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "modules__module__button__wrap r r--halign-center r--row-always",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "modules__module__button__icons",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "modules__module__button__icons__icon r r--justify-center r--halign-center",
                                                    },
                                                    [
                                                      _c("Icon", {
                                                        attrs: {
                                                          name: _vm.getModuleIconName(
                                                            mod.order
                                                          ),
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _vm._m(3, true),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "modules__module__button__text",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "r r--justify-center",
                                                    },
                                                    [_vm._v(_vm._s(mod.title))]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ms__bg" }),
                ]),
              ],
              1
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "plans__top type--content-styles type--content-margins type--content-links",
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "text__title header icon icon--bonus r r--row-always r--halign-center",
          },
          [
            _vm._v(
              "\n                                    Bonus Topics\n                                "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "modules__module__button__icons__icon__ring r r--justify-center r--halign-center",
      },
      [
        _c("div", {
          staticClass: "modules__module__button__icons__icon__ring__inner",
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "modules__module__button__icons__icon__ring__outer",
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "modules__module__button__text" }, [
      _c("span", { staticClass: "r r--justify-center" }, [
        _vm._v("Crave/Slip Help"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "modules__module__button__icons__icon__ring r r--justify-center r--halign-center",
      },
      [
        _c("div", {
          staticClass: "modules__module__button__icons__icon__ring__inner",
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "modules__module__button__icons__icon__ring__outer",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }