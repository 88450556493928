o<template>
  <div class="ms ms--regular" :class="index === activeSlide ? 'active' : 'inactive'" ref="parent" :data-id="index">
        <div class="ms__wrap c c--content--pad r pad--h v--40">
            <div class="ms__top v--15">
                <button
                    @click="prev"
                    class="ms__back link">< Back</button>
                     <!-- {{sd}} -->
                <div v-if="sd.feature_image" class="ms__image" style="overflow: hidden;">
                    <img :src="sd.feature_image"/>
                </div>     
                <div class="ms__content v--20">
                    <div v-for="instance in sd.panels_meta.widgets" :key="instance.panels_info.id">
                        <PanelText v-if="instance.panels_info.class === 'Text_Panel'" :instance="instance" :active="active"/>
                        <PanelSession v-if="instance.panels_info.class === 'Session_Panel'" :instance="instance"/>
                        <PanelVideoTeam v-if="instance.panels_info.class === 'Video_Team_Panel'" :instance="instance" :siloData="siloData" :active="active" @next="next" @close="$emit('close')" @toMenu="$emit('toMenu')"/>
                        <PanelCardSlider v-if="instance.panels_info.class === 'Card_Slider_Panel'" :instance="instance" :siloData="siloData"/>
                        <PanelShowHide v-if="instance.panels_info.class === 'Show_Hide_Panel'" :instance="instance" :siloData="siloData"/>
                        <PanelForm v-if="instance.panels_info.class === 'Form'" :instance="instance" :siloData="siloData" @next="next" @processing="setProcessing" @refreshData="refreshData"/>
                        <PanelAffirmation v-if="instance.panels_info.class === 'Affirmation_Panel'" :instance="instance" :siloData="siloData" :active="active"/>
                        <PanelEmbed v-if="instance.panels_info.class === 'Embed_Panel'" :instance="instance" :active="active" :pauseVideo="pauseVideo" v-bind:allowNext="true" @next="next" @playingVideo="playingVideo" @close="$emit('close')"/>
                        <PanelVariable v-if="instance.panels_info.class === 'Variable_Panel'" :instance="instance" :siloData="siloData"/>
                        <Forwarder v-if="instance.panels_info.class === 'Forwarder'" :instance="instance" :siloData="siloData" @next="next" />
                        <Badge v-if="instance.panels_info.class === 'Badge'" :instance="instance" :active="active" />
                        <Kudo v-if="instance.panels_info.class === 'Kudo'" :instance="instance" :active="active" />

                        <!-- Conditional panel needs *everything* attached to it -->
                        <Conditional 
                            v-if="instance.panels_info.class === 'Conditional'" 
                            :instance="instance" 
                            :siloData="siloData" 
                            :pauseVideo="pauseVideo"
                            :active="active"
                            @next="next"
                            @prev="prev" 
                            @processing="setProcessing" 
                            @refreshData="refreshData" 
                            @playingVideo="playingVideo" 
                        />
                    </div>
                </div>
            </div>
            <div class="ms__buttons r r--halign-center">
                <button v-if="!isLast && !hideButton" class="ms__next btn btn--solid" @click="next">Next ></button>
                <button v-if="isLastAndIsBonus && !hideButton" class="ms__home btn btn--solid" @click="$emit('toMenu')">Finish</button>
                <button v-if="isLastAndIsModule && !hideButton" class="ms__home btn btn--solid" @click="$emit('close')">Home</button>
            </div>
        </div>
        <div class="ms__bg"></div>
  </div>
</template>

<script>
import PanelText from "@c/panels/PanelText.vue";
import PanelSession from "@c/panels/PanelSession.vue";
import PanelVideoTeam from "@c/panels/PanelVideoTeam.vue";
import PanelCardSlider from "@c/panels/PanelCardSlider.vue";
import PanelForm from "@c/panels/PanelForm.vue";
import PanelAffirmation from "@c/panels/PanelAffirmation.vue";
import PanelEmbed from "@c/panels/PanelEmbed.vue";
import PanelVariable from "@c/panels/PanelVariable.vue";
import PanelShowHide from "@c/panels/PanelShowHide.vue";
import Conditional from "@c/panels/Conditional.vue";
import Forwarder from "@c/panels/Forwarder.vue";
import Badge from "@c/panels/Badge.vue";
import Kudo from "@c/panels/Kudo.vue";
export default {
    components: {
        PanelText,
        PanelSession,
        PanelVideoTeam,
        PanelCardSlider,
        PanelForm,
        PanelAffirmation,
        PanelEmbed,
        PanelVariable,
        PanelShowHide,
        Conditional,
        Forwarder,
        Badge,
        Kudo,
    },
	props: {
		sd: Object, // aka 'slide data'
        index: Number, // the index of this slide
        slides: Array, // all slide data
        siloData: Object,
        activeSlide: Number,
        active: Boolean,
	},
    data() {
        return {
            pauseVideo: true,
        } 
    },
    mounted() {
        //this.activeCheck();
    },
    watch: {
        /*
        active() {
            this.activeCheck();
        }
        */
    },
    computed: {
        // Adds 1 to index, since index is always based on 0
        actualIndex() {
            return this.index + 1;
        },
        isLast() {
            return this.actualIndex === this.slides.length ? true : false;
        },
        isLastAndIsModule() {
            return this.actualIndex === this.slides.length && this.sd.type === 'module' ? true : false;
        },
        isLastAndIsBonus() {
            return this.actualIndex === this.slides.length && this.sd.type !== 'module' ? true : false;
        },

        // Check to see if the slide has a form panel or any other one where we need to hide the button
        hideButton() {
            let returned = false;

            // Loop through panel widgets
            this.sd.panels_meta.widgets.forEach(instance => {
                // If we find a form panel, set the return to true
                if (instance.panels_info.class === 'Form' || instance.panels_info.class === 'Video_Team_Panel' || instance.panels_info.class === 'Conditional') {
                    returned = true;
                }
                // Hide the button if it's an embed panel AND it's required, otherwise use the module button
                if (instance.panels_info.class === 'Embed_Panel' && instance.required === true) {
                    returned = true;
                }

            });

            return returned;
        },
    },
    methods: {
        /*
        activeCheck() {
            if (this.active === true) {
                //console.log('MODULE SLIDE, THIS SLIDE IS NOW ACTIVE');
            }
        },
        */
        next() {
            // emit next event to parent
            this.$emit('next');
            this.pauseVideo = true;
        },
        prev() {
            // emit prev to parent
            this.$emit('prev');
            this.pauseVideo = true;
        },
        setProcessing(state) {
            console.log('setting processing (module slide)');
            this.$emit('processing', state);
        },
        refreshData(onComplete = null) {
            console.log('refreshing data (module slide)');
            this.$emit('refreshData', onComplete);
        },
        // Emitted when playing a video
        playingVideo() {
            this.pauseVideo = false;
        },
    }
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.ms {
    height: 100%;
    padding-top: var(--height--header-m);
    overflow-y: scroll;
    overflow-x: hidden;
}
.ms__wrap {
    z-index: 1;
}
.ms__bg {
    background-color: var(--color-white);
}
</style>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.ms__wrap {
    padding-top: rem(20px);
    
    padding-bottom: rem(50px);
    /* TODO: Make this extend full left and full right
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-white);
    }
    */
}
.ms--regular {
    .ms__wrap {
        background-color: var(--color-white);
    }
}
.ms__content {

}
</style>