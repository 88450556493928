<template>
    <div v-if="siloData.user.id !== 0" id="pageloader" :data-slide-direction="transitionName">
        <div class="pageloader">
            <div v-if="currentPageData" class="page" :data-active-slide="activeSlide">
                <TransitionGroup :name="transitionName" tag="div" style="height:100%">
                    <div v-for="(slide, index) in currentPageData" 
                        v-show="activeSlide === slide.slug"
                        class="page__slide" 
                        :key="slide.ID">
                        <PageSlide 
                            :sd="slide" 
                            :index="index" 
                            :slides="currentPageData"
                            :siloData="siloData"
                            :appState="appState"
                            :activeSlide="activeSlide"
                            :active="activeSlide === slide.slug ? true : false"
                            @home="home()"
                            @updateState="updateState()"
                        />
                    </div>
                </TransitionGroup>
            </div>
        </div>
    </div>
</template>

<script>
import PageSlide from "@c/PageSlide.vue";
const axios = require('axios');
export default {
    components: {
        PageSlide,
    },
    data() {
        return {
            state: "inactive",
            currentPageData: null,
            processing: false,
            activeSlide: 0,
            anims: {
                loadModuleJS: 550,
            },
            transitionName: 'show-next',
        }
    },
    computed: {
        activeSlideIndex() {
            return this.currentPageData.findIndex(x => x.slug === this.activeSlide);
        }
    },
    props: {
        siloData: Object,
        appState: String,
        pageId: Number,
        active: Boolean,
    },   
    watch: {
        // Reset the page loader when the app state changes
        // If the app state is not Get Help
        appState() {
            if (this.appState === "home") {
                this.reset();
            }
        },
        active() {
            if (this.active === true) {
                this.postPageView();
            }
        }
    },
    mounted() {
        // We just load all the pages when we mount
        // because there's nothing dynamic
        this.loadPages();

        // Remove this after I figure out page navigation better
        window.pageLoader = this;
    },
    methods: {
        // Pull a page and it's children
        loadPages() {
            if (this.pageId === null) return;
            console.log('loading page', this.pageId);

            // I had to make this URL static because it loads before the silodata is loaded
            // I need to make a second set of data that is just static data
            axios.post(this.siloData.urls.getpage, {
                id: this.pageId,
            }).then((result) => {
                console.log(result);
                
                this.currentPageData = result.data;
                this.activeSlide = result.data[0].slug;
                this.state = 'active';

                // Check to see if we have an active slide string
                // this.checkForSlideQueryString();
                
            }).catch((reason) => {
                console.log(reason);
            });
        },
        // Go back to first page
        home() {
            this.transitionName = "show-prev";
            this.activeSlide = this.currentPageData[0].slug;
            this.postPageView();
        },
        set(slug) {
            this.transitionName = "show-next";
            this.activeSlide = slug;
            this.postPageView();
        },
        reset() {
            console.log('reseting page loader');
            // Set as inactive
            this.state = "inactive";
            // Set active slide back to the first
            this.activeSlide = this.currentPageData[0].slug;
        },
        postPageView() {
            let data = {
                type: 'help',
                title: this.currentPageData[this.activeSlideIndex].title,
            };
            window.events.postPageView(data);
        },
        updateState() {
            this.reset();
            this.$emit('updateState', 'bonus-topics')
        },
    } 
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
#pageloader {
    display: flex;
}
.pageloader {

}
.page {
    height: 100%;
    transition: all 0.15s;
}

.page__slide {
    width: 100vw;
    height: 100%;
    position: absolute;
    > * {
        width: 100vw;
    }
}
</style>