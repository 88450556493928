var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.siloData.user.id !== 0
    ? _c(
        "span",
        {
          attrs: {
            id: "moduleloader",
            "data-state": _vm.state,
            "data-slide-direction": _vm.transitionName,
          },
          on: { resetModule: _vm.reset },
        },
        [
          _c(
            "div",
            {
              staticClass: "moduleloader bonusloader",
              style: `transition-duration: ${_vm.loadModule}`,
              attrs: {
                "data-processing": _vm.processing,
                "data-appstate": _vm.appState,
                "data-module-theme": _vm.current_module_theme,
              },
            },
            [
              _vm.current_module_data
                ? _c(
                    "div",
                    {
                      staticClass: "module",
                      attrs: { "data-active-slide": _vm.active_slide },
                    },
                    [
                      _c("EntryHeaderModules", {
                        attrs: {
                          slideParent: _vm.current_module_data[0],
                          slides: _vm.current_module_data,
                          activeSlide: _vm.active_slide,
                        },
                        on: {
                          close: function ($event) {
                            return _vm.reset()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "TransitionGroup",
                        {
                          staticStyle: { height: "100%" },
                          attrs: { name: _vm.transitionName, tag: "div" },
                          on: {
                            leave: _vm.leave,
                            enter: _vm.enter,
                            "after-enter": _vm.afterEnter,
                          },
                        },
                        _vm._l(
                          _vm.current_module_data,
                          function (slide, index) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.active_slide === index,
                                    expression: "active_slide === index",
                                  },
                                ],
                                key: index,
                                staticClass: "module__slide",
                                class:
                                  _vm.active_slide === index
                                    ? "active"
                                    : "inactive",
                                attrs: { "data-slide": index },
                              },
                              [
                                index == 0
                                  ? _c("ModuleSlideIntro", {
                                      attrs: {
                                        sd: slide,
                                        index: index,
                                        slides: _vm.current_module_data,
                                        type: "bonus",
                                      },
                                      on: { next: _vm.next },
                                    })
                                  : _c("ModuleSlide", {
                                      attrs: {
                                        sd: slide,
                                        index: index,
                                        slides: _vm.current_module_data,
                                        siloData: _vm.siloData,
                                        activeSlide: _vm.active_slide,
                                        active:
                                          _vm.active_slide === index
                                            ? true
                                            : false,
                                      },
                                      on: {
                                        processing: _vm.setProcessing,
                                        refreshData: _vm.refreshData,
                                        next: _vm.next,
                                        prev: _vm.prev,
                                        toMenu: function ($event) {
                                          return _vm.toMenu()
                                        },
                                        close: function ($event) {
                                          return _vm.reset()
                                        },
                                      },
                                    }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("WelcomeModal", {
            attrs: {
              appState: _vm.appState,
              siloData: _vm.siloData,
              active: _vm.showWelcomeActive,
              module_id: _vm.current_module_theme,
            },
            on: {
              moduleWelcomeReset: _vm.moduleWelcomeReset,
              refreshData: _vm.refreshData,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }