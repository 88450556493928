<template>
    <div class="cs v--30">
        <!-- {{instance}} -->
        <div class="cs__top v--15">
            <h2 class="cs__title type--h2" v-html="instance.title"></h2>
            <div class="cs__content type--content-links type--content-margins type--content-styles"
                v-html="instance.content"></div>
        </div>
        <VueSlickCarousel class="cs__slider" ref="slider" v-bind="settings">
            <div v-for="(member, index) in instance.team" :key="index" class="cs__card r"
                :data-member-name="member.name" data-watched="false">
                <!-- {{member}} -->
                <div class="cs__card__wrap">
                    <div class="cs__card__container r r--justify-center v--15">
                        <div v-if="member.name !== 'na'" class="cs__card__top r r--justify-center r--halign-center v--5">
                            <div class="cs__card__image vt__member__image">
                                <PictureStaticAsset :url_sd="getImage(member).sd" :url_hd="getImage(member).hd" />
                                <div class="cs__card__image__bg vt__member__image__bg"></div>
                            </div>
                            <h5 class="cs__card__name">{{member.name}}</h5>
                        </div>
                        <div class="cs__card__bottom v--10" :class="member.wysiwyg === '' ? 'type--content-styles' : ''">
                            <p v-if="member.header" class="cs__card__header"><strong>{{member.header}}</strong></p>
                            <p v-if="member.content" class="cs__card__content"><em>{{member.content}}</em></p>
                            <div v-if="member.wysiwyg" class="cs__card__wysiwyg type--content-styles v--15" v-html="member.wysiwyg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import PictureStaticAsset from "@c/PictureStaticAsset.vue";
import VueSlickCarousel from 'vue-slick-carousel';
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    components: {
        PictureStaticAsset,
        VueSlickCarousel,
    },
    data() {
        return {
            settings: {
                arrows: false,
                dots: true,
                //slidesToShow: 2,
                slidesToScroll: 1,
                //centerMode: true,
                centerPadding: "25px",
                draggable: true,
                infinite: false,
                fixedWidth: "257px",
                variableWidth: true,
            }
        }
    },
    props: {
        instance: Object,
        siloData: Object,
    },
    methods: {
        getImage(member) {
            let name = member.name.toLowerCase();
            return { 
                "sd":this.siloData.images[name].person.sd[0] ? this.siloData.images[name].person.sd[0] : "",
                "hd":this.siloData.images[name].person.hd[0] ? this.siloData.images[name].person.hd[0] : "",
            };
        }
    }
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.cs__slider {
    //overflow: hidden;

}
.cs__card {
    padding: 0 rem(10px);
    height: 100%;
}
.cs__card__wrap {
    width: rem(257px);
    height: rem(253px);
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.5);
    padding: rem(20px) rem(20px) rem(30px) rem(20px);
    background-color: var(--color-white);
    height: 100%;
    //margin: rem(25px) 0 rem(25px);
}

.slick-list {
    padding-top: rem(10px);
    padding-bottom: rem(25px);
    overflow: visible !important;
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    padding-top: rem(30px);
    > * + * {
        margin-left: rem(10px);
    }
    > * {

        > * {
            text-indent: -9999px;
            width: rem(14px);
            height: rem(14px);
            border-radius: 100%;
            background-color: var(--color-grey);
        }
        &.slick-active {
            > * {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}
.slick-active {

}
.slick-track {
    display: flex;
    align-content: stretch;
    align-items: stretch;
}
.slick-slide {
    height: auto;
    > * {
        height: 100%;
    }
}
</style>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.cs__card__name {
    font-size: rem(15px);
    font-weight: $weight_bold;
    letter-spacing: 0.58px;
}
.cs__card__content {
    font-weight: $weight_medium;
}
.vt__member__image {
    width: rem(120px);
    height: rem(120px);
}

.vt__member__image {
    .img{
        width: rem(110px);
        height: rem(110px);
    }
    img {
        width: 100% !important;
        height: 100% !important;
        object-fit:cover;
    }
}
</style>