<template>
  <div id="entry-header-modules" class="eh bg-color--dark">
    <div class="eh__content c c--content--pad r r--justify-space-between r--row-always r--halign-center pad--h v--5">
        <div class="eh__content__home-link">
            <button @click="$emit('close')">
                <Icon name="icon-home"/>
            </button>
        </div>           
        <div v-show="activeSlide !== 0" class="eh__content__title">
            <span>{{slideParent.title}}</span>
        </div>
        <div class="eh__content__logout">
            <button onclick="uma.logout();return false;" aria-title="Logout">
                <div class="icon icon--logout"></div>
            </button>
        </div>
    </div>
    <div class="eh__bg"></div>
  </div>
</template>

<script>
import Icon from '@c/Icon.vue';
export default {
    components: {
        Icon,
    },
    data() {
        return {

        }
    },
	props: {
        slideParent: Object, // aka 'slide data'
        slides: Array, // all slide data
        activeSlide: Number,
	},
}
</script>

<style lang="scss" scoped>
.eh {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
}
.eh__bg {
    height: var(--height--header-m);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: purple;
    z-index: 0;
    transition: all .4s;
    transform: translateY(-100%);
}
.eh__content {
    z-index: 1;
}
[data-appstate="module-intro"] {
    .eh {
        background-image: none !important;
    }
}
[data-appstate="module"],
[data-appstate="bonus"] {
    .eh__bg {
        transform: translateY(0);
    }
}

</style>