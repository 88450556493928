var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "parent",
      staticClass: "ms ms--regular",
      class: _vm.index === _vm.activeSlide ? "active" : "inactive",
      attrs: { "data-id": _vm.index },
    },
    [
      _c("div", { staticClass: "ms__wrap c c--content--pad r pad--h v--40" }, [
        _c("div", { staticClass: "ms__top v--15" }, [
          _c(
            "button",
            { staticClass: "ms__back link", on: { click: _vm.prev } },
            [_vm._v("< Back")]
          ),
          _vm._v(" "),
          _vm.sd.feature_image
            ? _c(
                "div",
                {
                  staticClass: "ms__image",
                  staticStyle: { overflow: "hidden" },
                },
                [_c("img", { attrs: { src: _vm.sd.feature_image } })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ms__content v--20" },
            _vm._l(_vm.sd.panels_meta.widgets, function (instance) {
              return _c(
                "div",
                { key: instance.panels_info.id },
                [
                  instance.panels_info.class === "Text_Panel"
                    ? _c("PanelText", {
                        attrs: { instance: instance, active: _vm.active },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Session_Panel"
                    ? _c("PanelSession", { attrs: { instance: instance } })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Video_Team_Panel"
                    ? _c("PanelVideoTeam", {
                        attrs: {
                          instance: instance,
                          siloData: _vm.siloData,
                          active: _vm.active,
                        },
                        on: {
                          next: _vm.next,
                          close: function ($event) {
                            return _vm.$emit("close")
                          },
                          toMenu: function ($event) {
                            return _vm.$emit("toMenu")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Card_Slider_Panel"
                    ? _c("PanelCardSlider", {
                        attrs: { instance: instance, siloData: _vm.siloData },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Show_Hide_Panel"
                    ? _c("PanelShowHide", {
                        attrs: { instance: instance, siloData: _vm.siloData },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Form"
                    ? _c("PanelForm", {
                        attrs: { instance: instance, siloData: _vm.siloData },
                        on: {
                          next: _vm.next,
                          processing: _vm.setProcessing,
                          refreshData: _vm.refreshData,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Affirmation_Panel"
                    ? _c("PanelAffirmation", {
                        attrs: {
                          instance: instance,
                          siloData: _vm.siloData,
                          active: _vm.active,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Embed_Panel"
                    ? _c("PanelEmbed", {
                        attrs: {
                          instance: instance,
                          active: _vm.active,
                          pauseVideo: _vm.pauseVideo,
                          allowNext: true,
                        },
                        on: {
                          next: _vm.next,
                          playingVideo: _vm.playingVideo,
                          close: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Variable_Panel"
                    ? _c("PanelVariable", {
                        attrs: { instance: instance, siloData: _vm.siloData },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Forwarder"
                    ? _c("Forwarder", {
                        attrs: { instance: instance, siloData: _vm.siloData },
                        on: { next: _vm.next },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Badge"
                    ? _c("Badge", {
                        attrs: { instance: instance, active: _vm.active },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Kudo"
                    ? _c("Kudo", {
                        attrs: { instance: instance, active: _vm.active },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Conditional"
                    ? _c("Conditional", {
                        attrs: {
                          instance: instance,
                          siloData: _vm.siloData,
                          pauseVideo: _vm.pauseVideo,
                          active: _vm.active,
                        },
                        on: {
                          next: _vm.next,
                          prev: _vm.prev,
                          processing: _vm.setProcessing,
                          refreshData: _vm.refreshData,
                          playingVideo: _vm.playingVideo,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ms__buttons r r--halign-center" }, [
          !_vm.isLast && !_vm.hideButton
            ? _c(
                "button",
                {
                  staticClass: "ms__next btn btn--solid",
                  on: { click: _vm.next },
                },
                [_vm._v("Next >")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLastAndIsBonus && !_vm.hideButton
            ? _c(
                "button",
                {
                  staticClass: "ms__home btn btn--solid",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toMenu")
                    },
                  },
                },
                [_vm._v("Finish")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLastAndIsModule && !_vm.hideButton
            ? _c(
                "button",
                {
                  staticClass: "ms__home btn btn--solid",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Home")]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ms__bg" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }