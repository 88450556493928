import { render, staticRenderFns } from "./Help.vue?vue&type=template&id=67052296&"
import script from "./Help.vue?vue&type=script&lang=js&"
export * from "./Help.vue?vue&type=script&lang=js&"
import style0 from "./Help.vue?vue&type=style&index=0&id=67052296&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nicholashaskell/Local/kicknic/app/public/wp-content/themes/kicknic/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67052296')) {
      api.createRecord('67052296', component.options)
    } else {
      api.reload('67052296', component.options)
    }
    module.hot.accept("./Help.vue?vue&type=template&id=67052296&", function () {
      api.rerender('67052296', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/scripts/components/Help.vue"
export default component.exports