<template>
    <div>
        <transition name="slide">
            <nav v-if="siloData.user.id !== 0" v-show="appState === 'home'" id="tools" class="tools">
                <ul class="tools__list c c--content--pad r r--row-always r--justify-space-between r--halign-center pad--h">
                    <li>
                        <button class="tools__list__button r r--row-always r--halign-center"
                            onclick="vapetracker.open()">
                            <Icon name="icon-tools-track-2" />
                            <span>Track</span>
                        </button>
                    </li>
                    <!--
                    <li>
                        <button class="tools__list__button r r--row-always r--halign-center"
                            @click="$emit('updateState', 'help')">
                            <Icon name="icon-tools-help" />
                            <span>Crave/Slip</span>
                        </button>
                    </li>
                    -->
                    <li>
                        <button class="tools__list__button r r--row-always r--halign-center"
                            @click="$emit('updateState', 'plan')">
                            <Icon name="icon-tools-plan-2" />
                            <span>Plan</span>
                        </button>
                    </li>
                    <li>
                        <button class="tools__list__button r r--row-always r--halign-center"
                            @click="$emit('updateState', 'tasks')">
                            <Icon name="icon-tools-tasks-2" />
                            <span>Tasks</span>
                        </button>
                    </li>
                    <li>
                        <button class="tools__list__button r r--row-always r--halign-center"
                            @click="$emit('updateState', 'bonus-topics')">
                            <Icon name="icon-tools-bonus-topics-2" />
                            <span>Bonus<br/>Topics</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </transition>
    </div>
</template>

<script>
import Icon from '@c/Icon.vue';
export default {
    components: {
        Icon,
    },
    data() {
        return {
            // theme: 'blue'
        }
    },
    props: {
        appState: String,
        siloData: Object
    },
    methods: {
        say(message) {
            window.alert(message);
        }
    }
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.tools {
    background-color: var(--color-lime);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    svg {
        //fill: var(--color-light-black);
        * {
            //fill: var(--color-light-black);
        }
    }
}
.tools__list {
    height: var(--height--toolbelt);
    max-width: rem(440px);
    
    @media screen and (max-width: $bp_phone) {
        padding-inline: rem(20px);
    }
}
.tools__list__button {
    > * + * {
        margin-left: rem(5px);
    }
    span {
        font-size: rem(11px);
        line-height: lh(11px, 11px);
        text-transform: uppercase;
        font-weight: $weight_bold;
    }
}
.slide-enter-active,
.slide-leave-active {
    transition: all 0.15s linear;
}

.slide-enter,
.slide-leave-to
    {
    transform: translateY(100%);
}
</style>